<template>
  <div class="content-wrapper">
    <page-header
      screen-name="Avisos"
      :link-items="linkItems"
    />

    <overlay
      :show="loading"
    >
      <div class="card p-2">
        <b-row
          class="mb-2"
        >
          <b-col>
            <b-link
              type="button"
              class="btn custom-blue btn-add"
              :to="{name: 'aviso-register'}"
            >
              <feather-icon
                icon="PlusIcon"
              />
              Adicionar novo aviso
            </b-link>
          </b-col>
        </b-row>

        <Filters
          :disable-button-search="table.tableBusy"
          :disable-button-clear="getDisableButtonClear || table.tableBusy"
          @actionSearch="handleSubmitFormFilters"
          @actionClear="clearFilters"
        >
          <b-form>
            <b-row class="align-items-center">
              <b-col
                sm="6"
                lg="4"
              >
                <b-form-group
                  label="Título do aviso"
                  label-for="alertName"
                  label-class="font_size_label"
                >
                  <b-form-input
                    id="alertName"
                    v-model="search.alertName"
                    maxlength="100"
                    placeholder="Título do aviso"
                    autocomplete="off"
                  />
                </b-form-group>
              </b-col>

              <b-col
                sm="6"
                lg="4"
              >
                <b-form-group
                  label="Perfil"
                  label-for="profile"
                  label-class="font_size_label"
                >
                  <v-select
                    id="profile"
                    v-model="search.profile"
                    :options="profiles"
                    variant="custom"
                    item-text="descricao"
                    item-value="id_perfil"
                    placeholder="Selecione o perfil"
                    label="descricao"
                  >
                    <template
                      v-slot:no-options
                    >
                      Nenhum registro encontrado.
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col
                sm="6"
                lg="4"
              >
                <b-form-group
                  label="Status"
                  label-for="active"
                  label-class="font_size_label"
                >
                  <v-select
                    id="active"
                    v-model="search.status"
                    :options="statusForm"
                    variant="custom"
                    item-text="description"
                    item-value="id"
                    placeholder="Selecione o status"
                    label="description"
                  >
                    <template
                      v-slot:no-options
                    >
                      Nenhum registro encontrado.
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </Filters>

        <b-row class="mb-2">
          <b-col
            class="px-3"
            sm="6"
          >
            <div
              v-if="showTable"
              class="d-flex justify-center-center align-items-center"
            >
              <span class="mr-50">Mostrar</span>
              <v-select
                id="orders"
                v-model="paginationData.defaultSize"
                :options="table.tableRows"
                :clearable="false"
                @input="updateQtdView($event)"
              >
                <span slot="no-options">Nenhuma opção selecionável.</span>
              </v-select>
            </div>
          </b-col>

          <b-col
            cols="12"
            class="text-center"
          >
            <p
              v-if="table.empty"
              class="table-empty mt-3"
            >
              Nenhum registro encontrado.
            </p>

            <p
              v-if="table.tableError"
              class="table-empty mt-3"
            >
              Sistema de busca indisponível no momento.
            </p>
          </b-col>

          <b-col
            v-if="loadingTable"
            cols="12"
          >
            <div
              v-if="table.tableBusy"
              class="spinner-area"
            >
              <b-spinner class="spinner-border text-custom-blue" />
            </div>
          </b-col>

          <b-col
            v-if="showTable"
            cols="12"
            class="my-2"
          >
            <b-table
              responsive
              sticky-header="380px"
              :busy.sync="table.tableBusy"
              :no-local-sorting="true"
              :fields="table.fields"
              :items="table.items"
              @context-changed="handleOrderTable"
            >
              <template #cell(titulo)="row">
                <span
                  v-b-tooltip.hover.rightbottom="{ variant: 'light' }"
                  class="font-weight-bold comprimento_maximo_celulas"
                  :title="row.item.titulo"
                >
                  {{ row.item.titulo }}
                </span>
              </template>

              <template #cell(conteudo)="row">
                <vue-perfect-scrollbar
                  class="altura_max_scrollbar"
                  :settings="settings"
                >
                  <div class="d-flex flex-wrap">
                    {{ row.item.conteudo }}
                  </div>
                </vue-perfect-scrollbar>
              </template>

              <template #cell(ids_perfis)="row">
                <vue-perfect-scrollbar
                  class="altura_max_vacinas_scrollbar"
                  :settings="settings"
                >
                  <div class="d-flex flex-wrap ">
                    <div
                      v-for="perfil in row.item.perfis"
                      :key="perfil.id_perfil"
                      class="rounded perfis custom animate__animated animate__fadeIn"
                    >
                      {{ perfil.descricao }}
                    </div>
                  </div>
                </vue-perfect-scrollbar>
              </template>

              <template #cell(vigencia_inicial)="row">
                <span
                  v-b-tooltip
                  class="font-weight-bold comprimento_maximo_celulas"
                >
                  {{ formatDate(row.item.vigencia_inicial) }}
                </span>
              </template>

              <template #cell(vigencia_final)="row">
                <span
                  v-b-tooltip
                  class="font-weight-bold comprimento_maximo_celulas"
                >
                  {{ formatDate(row.item.vigencia_final) }}
                </span>
              </template>

              <template #cell(ativo)="row">
                <span
                  v-b-tooltip
                  class="font-weight-bold comprimento_maximo_celulas rounded-pill custom"
                  :class="row.item.status ? row.item.status.class : ''"
                >
                  {{ row.item.status ? row.item.status.content : '' }}
                </span>
              </template>

              <template #cell(actions)="row">
                <feather-icon
                  id="view-aviso"
                  v-b-tooltip.hover.html
                  role="button"
                  class="text-custom-blue mr-1"
                  icon="EyeIcon"
                  size="18"
                  title="<b>Detalhes do Aviso</b>"
                  @click.prevent="redirectToShow(row.item)"
                />
                <feather-icon
                  v-if="row.item.status.changeable"
                  id="edit-aviso"
                  v-b-tooltip.hover.html
                  role="button"
                  class="text-custom-blue"
                  icon="EditIcon"
                  size="18"
                  title="<b>Editar Aviso</b>"
                  @click.prevent="redirectToEdit(row.item)"
                />
              </template>
            </b-table>
          </b-col>

          <b-col
            v-if="showTable"
            class="px-3"
            sm="12"
          >
            <CustomPagination
              :paginacao="paginationData"
              @page-cliked="updateCurrentPage"
            />
          </b-col>
        </b-row>
      </div>
    </overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BTable,
  BSpinner, BLink, BFormInput, VBTooltip,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import vSelect from 'vue-select'
import moment from 'moment'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import Overlay from '@/views/components/custom/overlay/Overlay.vue'
import {
  warningMessage,
} from '@/libs/sweetalerts'
import Filters from '@/views/components/custom/filters/Filters.vue'
import { getProfiles } from '@/views/custom-pages/gerenciarUsuarios/requests'
import { TIPONOTIFICACAO } from '@/enum/tipo-notificacao'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'

export default {
  title: 'Adesão Nacional',

  directives: {
    'b-tooltip': VBTooltip,
  },

  components: {
    BFormInput,
    VuePerfectScrollbar,
    Filters,
    BLink,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BTable,
    BSpinner,
    Overlay,
    vSelect,
    PageHeader,
    CustomPagination,
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Avisos',
          active: true,
          routeName: '',
        },
      ],

      moment,

      statusForm: [
        { id: 1, description: 'Ativo' },
        { id: 2, description: 'Inativo' },
        { id: 3, description: 'Expirado' },
      ],

      loading: true,
      loadingTable: false,

      titlePage: '',

      showTable: false,
      switchDisabled: false,

      paginationData: {
        currentPage: 0,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },

      settings: {
        maxScrollbarLength: 70,
        wheelSpeed: 0.2,
        wheelPropagation: false,
      },

      profiles: [],

      search: {
        alertName: '',
        profile: null,
        status: null,
      },

      table: {
        empty: false,
        tableError: false,
        tableBusy: true,
        tableRows: [10, 25, 50, 100],
        tableOrder: '',
        tableColumnNameOrder: '',
        fields: [
          {
            key: 'titulo', label: 'TÍTULO DO AVISO', sortable: true, class: 'mw-300',
          },
          {
            key: 'ids_perfis', label: 'PERFIS', class: 'mw-200',
          },
          {
            key: 'vigencia_final', label: 'DATA LIMITE', sortable: true, class: 'mw-150 text-center col-1',
          },
          {
            key: 'ativo', label: 'STATUS', sortable: true, class: 'mw-150 text-center col-1',
          },
          {
            key: 'actions',
            label: 'AÇÕES',
            class: 'width-custom-column text-center',
          },
        ],
        items: [],
      },
    }
  },

  computed: {
    getDisableButtonClear() {
      return [
        this.search.alertName === '',
        this.search.profile === null,
        this.search.status === null,
      ].every(field => field === true)
    },
  },

  mounted() {
    this.index()
  },

  methods: {
    async index() {
      this.loading = true

      await getProfiles()
        .then(response => {
          this.profiles = response.data
        })

      this.loading = false

      this.loadingTable = true

      this.findAll()
    },

    handleSubmitFormFilters() {
      this.paginationData.currentPage = 1

      this.findAll()
    },

    findAll() {
      this.table.tableBusy = true

      this.table.tableError = false
      this.table.empty = false

      this.$http.get(this.$api.saveAndGetNotificacao(), { params: this.getParams() })
        .then(response => {
          if (response.status === 200) {
            if (response.data.data.length > 0) {
              const avisos = this.areClosed(response.data.data)

              this.showTable = true
              this.table.items = avisos
              this.table.tableBusy = false
              this.handlePagination(response.data)
              return
            }

            this.table.empty = true
            this.showTable = false
            this.table.tableBusy = false
          }
        })
        .catch(() => {
          this.table.tableError = true
          this.table.tableBusy = false
          this.showTable = false
        })
    },

    handleError(response) {
      const errors = response.status === 400 || response.status === 404

      if (errors) {
        warningMessage(
          response.data.errors,
        )

        return
      }

      warningMessage(
        'Não foi possível realizar a sua solicitação.<br /> Entre em contato com o suporte.',
      )
    },

    redirectToShow({
      id_notificacao, titulo, conteudo, vigencia_inicial, vigencia_final, ids_perfis, id_ajuda, topico, ativo,
    }) {
      this.$router.push(
        {
          name: 'aviso-show',
          params: {
            idNotificacao: id_notificacao,
            titulo,
            conteudo,
            vigencia_inicial,
            vigencia_final,
            ativo,
            ajudaRelacionada: {
              id_ajuda,
              topico,
            },
            perfisSelecionados: ids_perfis,
          },
        },
      )
    },

    redirectToEdit({
      id_notificacao, titulo, conteudo, vigencia_inicial, vigencia_final, ids_perfis, id_ajuda, ativo,
    }) {
      this.$router.push(
        {
          name: 'aviso-edit',
          params: {
            idNotificacao: id_notificacao,
            titulo,
            conteudo,
            vigencia_inicial,
            vigencia_final,
            ativo,
            ajudaRelacionada: id_ajuda,
            perfisSelecionados: ids_perfis,
          },
        },
      )
    },

    clearFilters() {
      this.paginationData.currentPage = 1

      this.search = {
        alertName: '',
        profile: null,
        status: null,
      }

      this.findAll()
    },

    handleOrderTable(context) {
      this.table.tableColumnNameOrder = context.sortBy
      this.table.tableOrder = context.sortDesc ? 'desc' : 'asc'

      this.findAll()
    },

    getParams() {
      return {
        colunaOrdenar: this.table.tableColumnNameOrder,
        ordem: this.table.tableOrder,
        tamanho: this.paginationData.defaultSize,
        pagina: this.paginationData.currentPage,

        tipo: TIPONOTIFICACAO.AVISO,
        id_perfil: this.search.profile ? this.search.profile.id_perfil : null,
        titulo: this.search.alertName,
        situacao: this.search.status ? this.search.status.description : null,
      }
    },

    areClosed(list) {
      list.filter(item => {
        if (!item.ativo) {
          item.status = {
            changeable: false,
            class: 'inativo',
            content: 'Inativado',
          }
        } else if (!moment(this.today).isAfter(item.vigencia_final)) {
          item.status = {
            changeable: true,
            class: 'ativo',
            content: 'Ativo',
          }
        } else {
          item.status = {
            changeable: false,
            class: 'expirado',
            content: 'Expirado',
          }
        }
      })
      return list
    },

    handlePagination(data) {
      this.paginationData.fromLine = data.from
      this.paginationData.toLine = data.to
      this.paginationData.totalLines = data.total
      this.paginationData.currentPage = data.current_page
    },

    updateQtdView(event) {
      if (!event) {
        this.paginationData.defaultSize = 10
      }

      this.paginationData.currentPage = 1
      this.findAll()
    },

    updateCurrentPage(page) {
      this.paginationData.currentPage = page
      this.findAll()
    },

    formatDate(value) {
      return moment(String(value)).format('DD/MM/YYYY')
    },
  },
}
</script>

<style lang="scss" scoped>
.m-button-icon {
  margin-right: 5px;
}

.margin-box {
  width: 20px;
  height: 22px;
}

.perfis{
  background-color: #2772C0;
  color: #ffffff;
}

.custom{
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  font-weight: 400;
}
.ativo{
  background-color: #e5f8ed;
  color: #28c76f;
}
.inativo{
  background-color: #fceaea;
  color: #ea5455;
}
.expirado{
  background-color: #fff3e8;
  color: #ff9f43;
}
.altura_max_scrollbar{
  max-height:100px;
  padding-right: 15px;
}
.comprimento_maximo_celulas{
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
}
.hidden_overflow_tabela{
  overflow: hidden;
}

@media (max-width: 400px) {
  .btn-add {
    width: 100%;
  }
}
</style>
